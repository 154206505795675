import { render, staticRenderFns } from "./Address.vue?vue&type=template&id=2e9df870&scoped=true&lang=pug&"
import script from "./Address.vue?vue&type=script&lang=ts&"
export * from "./Address.vue?vue&type=script&lang=ts&"
import style0 from "./Address.vue?vue&type=style&index=0&id=2e9df870&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2e9df870",
  null
  
)

export default component.exports